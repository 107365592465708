import { ChangeEvent, useRef, useState } from 'react';
import Pills from '../../../../componentsV2/DesignSystem/Pills';
import { FontWeight, Heading } from '../../../../componentsV2/DesignSystem/Typography';
import styles from './styles.module.scss';
import { Button } from '../../../../componentsV2/DesignSystem/Inputs/Buttons/ButtonAction';
import { ButtonStateControl, HeaderProps } from './interface';
import Input from '../../../../componentsV2/DesignSystem/Inputs/Input';
import useDebounce from '../../../../hooks/useDebounce';
import Modal from '../../../../componentsV2/DesignSystem/Modal';

export default function Header({ title, pills, exportCsv, search }: HeaderProps): JSX.Element {
  const sweepRef = useRef(null);
  const exportRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [, /* @todo v2 showSweepstakesDrawer*/ setShowSweepstakesDrawer] = useState(false);
  const [exportButtonControl, setExportButtonControl] = useState<ButtonStateControl>({
    disabled: true,
    popoverControl: false,
  });
  const [sweepButtonControl, setSweepButtonControl] = useState<ButtonStateControl>({
    disabled: true,
    popoverControl: false,
  });
  //@todo v2 const [data, setData] = useState<SweepstakeDataProps>(initData);
  const [searchValue, setSearchValue] = useState<string>('');
  const debounce = useDebounce({ ms: 500 });

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);

    if (value.length === 3) {
      search('search', value);
    } else if (value.length === 0 || value.length > 3) {
      debounce(() => search('search', value));
    }
  };

  function sendEmail(value: string[][]) {
    console.log(value);
  }

  return (
    <div className={styles['header-wrapper']}>
      <header>
        <div className={styles['title-wrapper']}>
          <Heading size={4} weight={FontWeight.extraBold}>
            {title}
          </Heading>

          {!!pills?.length && pills.map((eachPill, index) => <Pills key={index} {...eachPill} size="lg" />)}
        </div>
        <Input placeholder="Search" inputSize="sm" value={searchValue} onChange={(e) => handleSearch(e)} />
      </header>
      <div className={styles['wrapper-buttons']}>
        <Input placeholder="Search" inputSize="sm" value={searchValue} onChange={(e) => handleSearch(e)} />

        <Button
          disabled
          variant="secondary"
          firstIcon={{ children: 'emoji_events' }}
          onClick={() => setShowSweepstakesDrawer(true)}
          size="sm"
          ref={sweepRef}
          onMouseEnter={() => setSweepButtonControl({ ...sweepButtonControl, popoverControl: true })}
          onMouseLeave={() => setSweepButtonControl({ ...sweepButtonControl, popoverControl: false })}
          {...(!!sweepButtonControl.disabled && {
            popover: {
              color: 'dark',
              showPopover: sweepButtonControl.popoverControl,
              callback: () => undefined,
              hiddenOverlay: true,
              children: 'This feature is coming soon.',
              // @todo on v2 children: 'You need to be an Enterprise plan.',
              propRef: null,
            },
          })}
        >
          Create Sweepstakes
        </Button>

        <Button
          variant="secondary"
          firstIcon={{ children: 'file_download' }}
          onClick={() => setShowModal(true)}
          size="sm"
          ref={exportRef}
          disabled={exportButtonControl.disabled}
          onMouseEnter={() => setExportButtonControl({ ...exportButtonControl, popoverControl: true })}
          onMouseLeave={() => setExportButtonControl({ ...exportButtonControl, popoverControl: false })}
          {...(!!exportButtonControl.disabled && {
            popover: {
              color: 'dark',
              placement: 'top-end',
              showPopover: exportButtonControl.popoverControl,
              callback: () => undefined,
              hiddenOverlay: true,
              children: 'This feature is coming soon.',
              //@todo on v2 children: 'Sorry, you need to be an admin.',
              propRef: null,
            },
          })}
        >
          Export List
        </Button>
      </div>
      {showModal && (
        <Modal
          callback={() => setShowModal(false)}
          title="Export Records"
          size="small"
          customClass={styles['send-email-wrapper']}
        >
          <>
            <Button
              fullWidth
              variant="secondary"
              firstIcon={{ children: 'email' }}
              onClick={() => sendEmail(exportCsv)}
            >
              Send to Me
            </Button>
          </>
        </Modal>
      )}
      {/* @todo v2 <CreateSweepstakesDrawer
        data={data}
        setData={setData}
        showDrawer={showSweepstakesDrawer}
        setShowDrawer={setShowSweepstakesDrawer}
      /> */}
    </div>
  );
}
