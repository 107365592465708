import { ApolloError, useQuery } from '@apollo/client';
import { CUSTOM_FILTERS } from './filters.gql';
import { FilterList } from './interface';

export interface CustomFiltersVariables {
  accountId: number;
  category: string;
}

interface CustomFiltersResult {
  loading: boolean;
  data: FilterList[];
  fetchCustomFilters: (variables: CustomFiltersVariables) => void;
}

export function useFetchCustomFilters(accountId: number, category: string): CustomFiltersResult {
  const { data, loading, refetch } = useQuery<{
    customFilters: { data: FilterList[] };
  }>(CUSTOM_FILTERS, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId,
      category,
    },
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  const fetchCustomFilters = (variables: CustomFiltersVariables) => {
    refetch(variables);
  };

  if (loading) {
    return {
      loading: true,
      data: [],
      fetchCustomFilters,
    };
  }

  return {
    loading: false,
    data: data?.customFilters?.data || [],
    fetchCustomFilters,
  };
}
