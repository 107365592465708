import { gql } from '@apollo/client';

export const CUSTOM_FILTERS = gql`
  query customFilters($accountId: ID!, $category: String) {
    customFilters(accountId: $accountId, category: $category) {
      data {
        id
        enable
        name
        slug
        category
        type
        options {
          label
          value
        }
      }
    }
  }
`;
