import { FilterList } from './interface';

const FILTER_LIST: FilterList[] = [
  {
    id: 'promo_id',
    name: 'Promotion',
    options: [],
    enable: true,
    type: 'multi',
    slug: 'promo_id',
  },
  //@todo v2 {
  //   id: 'segment_name',
  //   name: 'Segment Name',
  //   slug: 'segment_name',
  //   type: 'multi',
  //   options: [],
  //   enable: true,
  // },
  {
    id: 'created_at',
    name: 'Create At',
    slug: 'created_at',
    type: 'multi',
    options: [
      { label: 'All Time', value: ' ' },
      { label: 'Today', value: 'today' },
      { label: 'This Week', value: 'this_week' },
      { label: 'This Month', value: 'this_month' },
    ],
    enable: true,
  },
];

export default FILTER_LIST;
