import { TableHeaderElement } from '../../../../../../componentsV2/TableColumnsControl/interface';

export const DEFAULT_COLUMNS: TableHeaderElement[] = [
  {
    id: '9990',
    name: 'Name',
    base_field: 'name',
    type: 'name',
    enabled: true,
    sortDisabled: true,
    order: 1,
    is_uid_field: false,
    width: '12rem',
    align: 'left',
  },
  {
    id: '9991',
    base_field: 'email',
    type: 'text',
    enabled: true,
    name: 'Email',
    sortDisabled: true,
    order: 2,
    width: '12rem',
    align: 'left',
  },
  {
    id: '9992',
    name: 'Created at',
    base_field: 'created_at',
    type: 'date',
    enabled: true,
    sortDisabled: true,
    order: 2,
    is_uid_field: false,
    width: '10rem',
    align: 'left',
  },
  {
    id: '9994',
    name: 'Invite Code',
    base_field: 'invite_code',
    type: 'text',
    enabled: true,
    sortDisabled: true,
    order: 4,
    is_uid_field: false,
    width: '15rem',
    align: 'left',
  },
  {
    id: '9993',
    name: 'Promotion',
    base_field: 'promotion_name',
    type: 'text',
    enabled: true,
    sortDisabled: true,
    order: 5,
    is_uid_field: false,
    width: '12rem',
    align: 'left',
  },
  {
    id: '9996',
    name: 'Result Message Title',
    base_field: 'result_message_title',
    type: 'text',
    enabled: true,
    sortDisabled: true,
    order: 7,
    is_uid_field: false,
    width: '10rem',
    align: 'left',
  },
  {
    id: '9997',
    name: 'Segment Label',
    base_field: 'segment_label',
    type: 'text',
    enabled: true,
    sortDisabled: true,
    order: 7,
    is_uid_field: false,
    width: '10rem',
    align: 'left',
  },
  {
    id: '9994',
    name: 'Terms and Conditions',
    base_field: 'terms',
    type: 'pill',
    enabled: true,
    sortDisabled: true,
    order: 6,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
  },
  {
    id: '9995',
    name: 'Newsletter',
    base_field: 'newsletter',
    type: 'pill',
    enabled: true,
    sortDisabled: true,
    order: 7,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
  },
  {
    id: '9996',
    name: 'Used at',
    base_field: 'used_at',
    type: 'text',
    enabled: true,
    sortDisabled: true,
    order: 6,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
  },
  {
    id: '9997',
    name: 'Expire Date',
    base_field: 'expire_date',
    type: 'text',
    enabled: true,
    sortDisabled: true,
    order: 7,
    is_uid_field: false,
    width: '10rem',
    align: 'center',
  },
];
