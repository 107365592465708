import { ApolloError, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { PaginationInput, SimplePaginationInfo, paginatorInfo } from '../../../../ts';
import { ENTRIES } from './entries.gql';

export interface EntriesFilters {
  order?: string;
  sort?: string;
  promo_id?: string;
  search?: string;
}

export interface EntriesVariables {
  accountId: number;
  pagination?: PaginationInput;
}

export interface Entries {
  id: string;
  entry_id?: string;
  avatar_image?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  company: string;
  promo_id: number;
  outcome_id: number;
  created_at: string;
  promotion_name: string;
  invite_code: string;
  newsletter: boolean;
  terms: boolean;
  result_message_title: string;
  segment_label: string;
  segment_id: string;
  has_prize: boolean;
}

interface EntriesResult {
  loading: boolean;
  data: Entries[];
  pagination: SimplePaginationInfo;
  fetchEntries: (variables: EntriesVariables) => void;
}

export function useFetchEntries(
  accountId: number,
  { page, limit }: PaginationInput,
  filters: EntriesFilters,
): EntriesResult {
  const [pagination, setPagination] = useState<{ total: number; currentPage: number; totalPerPage: number }>({
    total: 0,
    currentPage: 1,
    totalPerPage: 10,
  });

  const { data, loading, refetch } = useQuery<{
    entries: { data: Entries[]; paginatorInfo: paginatorInfo };
  }>(ENTRIES, {
    fetchPolicy: 'no-cache',
    variables: {
      accountId,
      pagination: {
        page,
        limit,
      },
      filters,
    },
    onError: (error: ApolloError) => {
      console.log(error.message);
    },
  });

  const fetchEntries = (variables: EntriesVariables) => {
    refetch(variables);
  };

  useEffect(() => {
    if (data?.entries.paginatorInfo) {
      setPagination({
        total: data.entries.paginatorInfo.total,
        currentPage: data.entries.paginatorInfo.current_page,
        totalPerPage: Number(data.entries.paginatorInfo.per_page),
      });
    }
  }, [data?.entries.paginatorInfo]);

  if (loading) {
    return {
      loading: true,
      data: [],
      pagination,
      fetchEntries,
    };
  }

  return {
    loading: false,
    data: data?.entries?.data || [],
    pagination,
    fetchEntries,
  };
}
