/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import TableColumnsControl, {
  buildTableResult,
  filterTableColumns,
} from '../../../../../../componentsV2/TableColumnsControl';
import {
  TableDataCellSerialized,
  TableHeaderElement,
  TypeColumn,
} from '../../../../../../componentsV2/TableColumnsControl/interface';
import useQueryParams from '../../../../../../hooks/useQueryParams';
import { Entries } from '../../useFetchEntries';
import { TableContentPros, ViewMoreEntries } from './interface';
import { convertToCsvKeys } from '../../../../../../utils/handleTables';
import { DELETE_ENTRY, ENTRY_VIEW_MORE } from '../../entries.gql';
import Drawer from '../../../../../../componentsV2/DesignSystem/Drawer';
import Skeleton from '../../../../../../componentsV2/DesignSystem/Skeleton';
import { Avatar } from '../../../../../../componentsV2/DesignSystem/Avatar';
import { FontWeight, Paragraph } from '../../../../../../componentsV2/DesignSystem/Typography';
import Modal from '../../../../../../componentsV2/DesignSystem/Modal';
import Alert from '../../../../../../componentsV2/DesignSystem/Alert';
import Input from '../../../../../../componentsV2/DesignSystem/Inputs/Input';
import styles from './styles.module.scss';
import { PillsColors } from '../../../../../../componentsV2/DesignSystem/Pills/interface';
import useQueryString from '../../../../../../hooks/useQueryString';
import useAccount from '../../../../../../hooks/useAccount';
import { useLazyQuery, useMutation } from '@apollo/client';
import useToast from '../../../../../../hooks/useToast';
import { useFetchEntriesFields } from '../../useFetchEntriesFields';
import Pills from '../../../../../../componentsV2/DesignSystem/Pills';
import { Tags } from '../../../../../../componentsV2/DesignSystem/Tags';
import { FormatDateDayLongMonthYear } from '../../../../../../componentsV2/DesignSystem/Utils/Format';
import { DEFAULT_COLUMNS } from './DefaultColumns';

export default function TableContent({
  data,
  dataLoading,
  refetch,
  exportSelectedCsv,
  setExportCsv,
  setExportSelectedCsv,
}: TableContentPros): JSX.Element {
  const [serializedTable, setSerializedTable] = useState<TableHeaderElement[]>();
  const { getAccount } = useAccount();
  const [fetch, { loading: viewMoreDataLoading }] = useLazyQuery<{
    entryViewMore: ViewMoreEntries;
  }>(ENTRY_VIEW_MORE, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ entryViewMore }) => {
      const listData = { ...entryViewMore };
      ['id', 'entry_id', 'first_name', 'last_name', 'email', 'avatar_image', '__typename'].forEach(
        (each) => delete listData[each],
      );

      const userSerialized: ViewMoreEntries = {
        entry_id: entryViewMore.entry_id,
        first_name: entryViewMore.first_name ?? '-',
        last_name: entryViewMore.last_name ?? '-',
        email: entryViewMore.email ?? '--',
        avatar_image: entryViewMore?.avatar_image ?? null,
        invite_code: entryViewMore.invite_code,
        listData: Object.entries(listData)
          ?.filter((each) => each[1] != null || each[1] != undefined)
          ?.map((each) => {
            let type;
            let value = String(each[1]).replace(/['"]+/g, '');
            const checkTypeof = typeof value;
            if (checkTypeof == 'object') {
              type = 'tag';
              value = JSON.stringify(value);
            } else if (checkTypeof == 'boolean' || value === 'true' || value === 'false') {
              type = 'pill';
              value = value;
            } else if (value.length > 10 && !!new Date(value).getDate()) type = 'date';
            else type = 'text';
            return {
              label: each[0].split('_').join(' '),
              value: value,
              type: type,
            };
          }),
      };
      setUserData({ ...userSerialized });
    },
    onError: () => {
      console.log('Check graphql logs');
    },
  });
  const { data: fieldsData, loading: fieldsDataLoading } = useFetchEntriesFields(getAccount.accountId);
  const [, setEntriesSelected] = useState<string[]>([]);
  const [userData, setUserData] = useState<ViewMoreEntries>({} as ViewMoreEntries);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState('');
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
  const { toast } = useToast();

  const query = useQueryString();

  function closeDrawer() {
    setShowDrawer(false);
  }

  const addQueryParam = useQueryParams();

  const handleCheckboxSelected = (itemsSelected: string[]) => {
    const entriesArray = convertToCsvKeys(data, itemsSelected, 'created_at');
    setEntriesSelected(itemsSelected);
    setExportSelectedCsv(entriesArray);
  };

  const toggleColumns = (data?: TableHeaderElement[], columns?: string) => {
    setSerializedTable(data);
    addQueryParam({ columns });
  };
  const getSerealized = (dataEntries: Entries[]) => {
    return dataEntries.map((entry) => {
      return {
        id: entry?.entry_id,
        entry_id: entry?.entry_id,
        name: !!entry?.first_name && !!entry?.last_name ? `${entry.first_name} ${entry.last_name}` : null,
        first_name: entry.first_name,
        last_name: entry.last_name,
        promotion_name: entry.promotion_name,
        email: entry.email,
        created_at: entry.created_at,
        result_message_title: entry.result_message_title,
        segment_label: entry.segment_label,
        newsletter:
          typeof entry.newsletter == 'boolean'
            ? entry.newsletter
              ? JSON.stringify({ pill: { children: 'Check', color: PillsColors.green } })
              : JSON.stringify({ pill: { children: 'Uncheck', color: PillsColors.gray } })
            : null,
        terms:
          typeof entry.terms == 'boolean'
            ? entry.terms
              ? JSON.stringify({ pill: { children: 'Check', color: PillsColors.green } })
              : JSON.stringify({ pill: { children: 'Uncheck', color: PillsColors.gray } })
            : null,
        status: entry.has_prize
          ? JSON.stringify({ pill: { children: 'Winner', color: PillsColors.green } })
          : JSON.stringify({ pill: { children: 'Unlucky', color: PillsColors.red } }),
        invite_code: entry.invite_code,
        phone: entry.phone,
        company: entry.company,
      } as TableDataCellSerialized;
    });
  };

  async function getEachData(id: number) {
    fetch({ variables: { accountId: Number(getAccount.accountId), entryId: id } });
    setShowDrawer(true);
  }

  const [deleteEntry, { loading: deleteEntryLoading }] = useMutation(DELETE_ENTRY, {
    onCompleted: () => {
      setShowDeleteModal(false);
      setShowDrawer(false);
      setShowBulkDeleteModal(false);
      setDeleteData('');
      toast('success', 'Entry deleted.');
      refetch({ accountId: getAccount.accountId });
    },
    onError: () => {
      setShowDeleteModal(false);
      setShowDrawer(false);
      setShowBulkDeleteModal(false);
      setDeleteData('');
      toast('error', 'Error to delete entry.');
      console.log('Check graphql logs.');
    },
  });

  const handleRenderList = (type: string, value: string | boolean) => {
    switch (type) {
      case 'tag':
        return (
          typeof value == 'string' &&
          value?.split(',').map((eachTag, index) => <Tags key={`tags-${index}`}>{eachTag}</Tags>)
        );
      case 'pill':
        return !!value && value != 'false' ? (
          <Pills size="lg" color={PillsColors.green}>
            Checked
          </Pills>
        ) : (
          <Pills size="lg" color={PillsColors.gray}>
            Unchecked
          </Pills>
        );
      case 'date':
        if (typeof value == 'string') {
          const parseDate = new Date(value.replace(/^"|"$/g, ''));
          return (
            <Paragraph size={2} weight={FontWeight.semiBold} color="gray-space">
              {typeof value == 'string' && !!parseDate && FormatDateDayLongMonthYear(parseDate)}
            </Paragraph>
          );
        } else return <></>;
      default:
        return (
          <Paragraph size={2} weight={FontWeight.semiBold} color="gray-space">
            {value}
          </Paragraph>
        );
    }
  };

  // Merge columns
  const mergeColumns = (defaultColumns: TableHeaderElement[], fields: TableHeaderElement[]) => {
    const excludeFields = [
      'name',
      'first_name',
      'last_name',
      'email',
      'terms',
      'terms_and_conditions',
      'newsletter',
      'promotion_name',
      'promotion',
      'code',
      'type',
      'created_at',
      'used_at',
      'expire_date',
    ];

    const newFields = fields.filter((each) => !excludeFields.includes(each.base_field));

    const additionalColumns = newFields.map((field, index) => {
      return {
        id: field.id,
        base_field: field.base_field,
        type: 'text' as TypeColumn,
        enabled: true,
        name: field.name,
        order: index + DEFAULT_COLUMNS.length + 1,
        sortDisabled: true,
      };
    });
    return [...defaultColumns, ...additionalColumns];
  };

  function handleBulkDelete(ids: string) {
    deleteEntry({ variables: { accountId: getAccount.accountId, entryId: ids } });
  }

  useEffect(() => {
    if (!dataLoading && !fieldsDataLoading) {
      const dataSerealized = getSerealized(data);
      const columnSeralized = mergeColumns(DEFAULT_COLUMNS, fieldsData).map((each, index) => {
        return {
          ...each,
          id: each.id,
          order: each.base_field == 'name' ? 1 : index + 2,
          type: each.base_field == 'name' ? 'name' : each.type,
        };
      });

      let result: TableHeaderElement[] = buildTableResult(
        columnSeralized.sort((a, b) => a.order - b.order),
        dataSerealized,
      );
      result = filterTableColumns(result, query.get('columns') ?? undefined);
      setSerializedTable(result);
      setExportCsv(dataSerealized.map((entry) => Object.values(entry)));
    }
  }, [data, dataLoading, fieldsData, fieldsDataLoading]);

  return (
    <TableColumnsControl
      firstColumnFixed
      filtersFixed
      column={serializedTable}
      toggleColumns={toggleColumns}
      loading={dataLoading}
      exportSelectedCsv={exportSelectedCsv}
      selectLine={{
        handleCheckboxSelected,
        columnId: 'created_at',
      }}
      bulkActions={{
        delete: handleBulkDelete,
        deleteLoading: deleteEntryLoading,
        showDeleteModal: showBulkDeleteModal,
        setShowDeleteModal: setShowBulkDeleteModal,
      }}
      viewMore={{
        callback: getEachData,
        data: userData,
        component: (
          <>
            <Drawer
              showDrawer={showDrawer}
              icon={{ children: 'person' }}
              title="Entry Details"
              callback={closeDrawer}
              footer={[
                {
                  children: 'Delete Entry',
                  variant: 'secondary',
                  size: 'md',
                  firstIcon: { children: 'delete', color: 'red-block' },
                  onClick: () => setShowDeleteModal(true),
                },
              ]}
            >
              <>
                {viewMoreDataLoading && userData ? (
                  <div className={styles['wrapper-entries-details']}>
                    <header>
                      <aside>
                        <Skeleton width="3.75rem" rounded={'50%'} height={3.75} />
                      </aside>
                      <div>
                        <Skeleton width="60%" height={1} />
                        <Skeleton width="40%" height={1} />
                      </div>
                    </header>
                    <main>
                      <ul>
                        <li>
                          <Skeleton width="20%" height={1} />
                          <Skeleton width="10%" height={1} />
                        </li>
                        <li>
                          <Skeleton width="20%" height={1} />
                          <Skeleton width="20%" height={1} />
                        </li>
                        <li>
                          <Skeleton width="20%" height={1} />
                          <Skeleton width="25%" height={1} />
                        </li>
                        <li>
                          <Skeleton width="20%" height={1} />
                          <Skeleton width="35%" height={1} />
                        </li>
                      </ul>
                    </main>
                  </div>
                ) : (
                  <div className={styles['wrapper-entries-details']}>
                    <header>
                      <aside>
                        <Avatar
                          name={
                            userData?.first_name && userData?.last_name
                              ? `${userData?.first_name} ${userData?.last_name}`
                              : '-'
                          }
                          size="xl"
                          image={userData?.avatar_image}
                        />
                      </aside>
                      <div>
                        <Paragraph size={1} weight={FontWeight.extraBold} color="gray-space">
                          {userData?.first_name && userData?.last_name
                            ? `${userData?.first_name} ${userData?.last_name}`
                            : '-'}
                        </Paragraph>
                        <Paragraph size={2} weight={FontWeight.semiBold} color="gray-space">
                          {userData?.email ?? '-'}
                        </Paragraph>
                      </div>
                    </header>
                    <main>
                      <ul>
                        {userData?.listData?.map((each, index) => (
                          <li key={`list-data-${index}`}>
                            <Paragraph size={3} weight={FontWeight.semiBold} color="gray-48">
                              {each.label}
                            </Paragraph>
                            {handleRenderList(each.type, each.value)}
                          </li>
                        ))}
                      </ul>
                    </main>
                  </div>
                )}
              </>
            </Drawer>
            {showDeleteModal && userData && (
              <Modal
                title="Really Delete"
                size="small"
                callback={() => setShowDeleteModal(false)}
                leftButton={{ children: 'Cancel', variant: 'secondary' }}
                rightButton={{
                  children: 'Delete',
                  variant: 'red-block',
                  loading: deleteEntryLoading,
                  disabled:
                    deleteEntryLoading || !(`DELETE ENTRY ${userData?.invite_code?.toLocaleUpperCase()}` == deleteData),
                  onClick: () =>
                    deleteEntry({ variables: { accountId: getAccount.accountId, entryId: userData.entry_id } }),
                }}
              >
                <div className={styles['wrapper-modal']}>
                  Are you sure you want to delete?
                  <Alert color="danger-light">This entry will become anonymous</Alert>
                  <Input
                    label={`Type DELETE ENTRY ${userData?.invite_code?.toLocaleUpperCase()} to confirm`}
                    placeholder={`DELETE ENTRY ${userData?.invite_code?.toLocaleUpperCase()}`}
                    value={deleteData}
                    onChange={(e) => setDeleteData(e.target.value.toLocaleUpperCase())}
                  />
                </div>
              </Modal>
            )}
          </>
        ),
      }}
    />
  );
}
