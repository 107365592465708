import { gql } from '@apollo/client';
import { paginatorInfoFragment } from '../../../../components/Pagination/paginator.gql';

export const ENTRY_VIEW_MORE = gql`
  query entryViewMore($accountId: ID!, $entryId: ID!) {
    entryViewMore(accountId: $accountId, entryId: $entryId) {
      entry_id
      first_name
      last_name
      email
      phone
      company
      promo_id
      created_at
      promotion_name
      invite_code
      result_message_id
      result_message_title
      segment_label
      segment_id
      newsletter
      terms
      has_prize
    }
  }
`;

export const ENTRIES = gql`
  query entries($accountId: ID!, $pagination: PaginationInput, $filters: AudienceFilters) {
    entries(accountId: $accountId, pagination: $pagination, filters: $filters) {
      data {
        entry_id
        first_name
        last_name
        email
        phone
        company
        promo_id
        created_at
        promotion_name
        invite_code
        terms
        newsletter
        result_message_id
        result_message_title
        segment_label
        segment_id
        has_prize
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const ENTRIES_FIELDS = gql`
  query entriesFields($accountId: ID!) {
    entriesFields(accountId: $accountId) {
      data {
        id
        name
        base_field
        slug
        is_uid_field
        type
        sortDisabled
        width
        enabled
        order
      }
      paginatorInfo {
        ...paginatorInfo_Part
      }
    }
  }
  ${paginatorInfoFragment}
`;

export const DELETE_ENTRY = gql`
  mutation deleteEntry($accountId: ID!, $entryId: ID!) {
    deleteEntry(accountId: $accountId, entryId: $entryId) {
      id
      message
    }
  }
`;
