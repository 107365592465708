import { useState } from 'react';
import Pagination from '../../../../../componentsV2/Pagination';
import { SimplePaginationInfo } from '../../../../../ts';
import styles from '../styles.module.scss';
import Header from '../../Header';
import TableContent from './TableContent';
import { Entries, EntriesVariables } from '../useFetchEntries';
import { PillsColors } from '../../../../../componentsV2/DesignSystem/Pills/interface';

interface ContentProps {
  data: Entries[];
  dataLoading: boolean;
  refetch: (variables: EntriesVariables) => void;
  pagination: SimplePaginationInfo;
  currentPage: number;
  handlePagination: (page: number) => void;
  search: (field: string, value: string) => void;
}

export default function Content({
  data,
  dataLoading,
  refetch,
  pagination,
  currentPage,
  handlePagination,
  search,
}: ContentProps): JSX.Element {
  const [exportCsv, setExportCsv] = useState<string[][]>([]);
  const [exportSelectedCsv, setSelectedExportCsv] = useState<string[][]>([]);

  return (
    <div className={styles['content-container']}>
      <Header
        title="All Entries"
        pills={[{ children: pagination.total.toString(), color: PillsColors.primary }]}
        exportCsv={exportCsv}
        search={search}
      />
      <TableContent
        data={data}
        dataLoading={dataLoading}
        refetch={refetch}
        exportSelectedCsv={exportSelectedCsv}
        setExportSelectedCsv={setSelectedExportCsv}
        setExportCsv={setExportCsv}
      />
      <footer>
        <Pagination
          total={pagination.total}
          currentPage={currentPage}
          totalPerPage={pagination.totalPerPage}
          setPage={handlePagination}
        />
      </footer>
    </div>
  );
}
